<template>

</template>

<script>
import Farben from "./ReaderFarben/Farben.vue"
import HermeneusIcon from "../../../hermeneus.icons/HermeneusIcon.vue"
import {EventBus} from "../../../hermeneus.eventbus/eventbus";
import FarbenMin from "./ReaderFarben/FarbenMin.vue"

export default {
name: "HasToolFarben",
  components: {
    'farben': Farben,
    'farben-min': FarbenMin,
    'hermeneus-icon': HermeneusIcon,
  },
  computed: {
    isAnyFarbeActive: function () {
      return this.$parent.$parent.$parent.Farben.filter(farbe => {
        return farbe.show === true;
      }).length > 0;
    }
  },
  props: ['farben'],
  methods: {
    toggleAllFarbenOff () {
      EventBus.$emit('hideAllFarben')
    }
  },

  mounted () {
  }
}
</script>

<style scoped>

</style>