<template>
  <div v-if="hasSubjPred">
    <button
        @click="toggle()"
        :class="{'olive reader-sentence-viewer_toolbar-button--active':this.active, 'img-grayscale hover-img-color': !this.active}"
        class="reader-toolbar-button button-header-subj-pred sentence-steps__panel--button flex-items-center light-grey hover-olive no-outline">
      <hermeneus-icon
          name="hermeneus-btn-attribute_s_p"
          height="20"
          width="20"
          class="hi-color-primary-300-hover"></hermeneus-icon>
    </button>

  </div>
</template>

<script>

import HasToolSubjPred from "../HasToolSubjPred.vue"

export default {
  name: "reader-header-toolbar-subjpred",
  extends: HasToolSubjPred
}
</script>

<style scoped>

</style>