<template>
  <div v-show="farben.length > 0" class="farbe-explanation flex-group-start align-self-center noselect">
    <button @click="toggleAllFarbenOff" class="reader-toolbar-button flex-group-start ml2 flex-items-center mr-2 no-outline"
            :class="{'olive reader-sentence-viewer_toolbar-button--active':this.isAnyFarbeActive, 'img-grayscale hover-img-color': !this.isAnyFarbeActive}"
    >

      <hermeneus-icon
          name="hi-farben-min"
          height="20"
          width="20"
          class="hi-color-primary-300"></hermeneus-icon>


    </button>
    <farben-min :farben="farben" class="flex flex-col md-plus:flex-row justify-start text-lg "></farben-min>
  </div>
</template>

<script>

import HasToolFarben from "../HasToolFarben.vue"

export default {
  name: "reader-header-toolbar-farben",
  extends: HasToolFarben
}
</script>

<style scoped>

</style>