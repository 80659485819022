<template>
  <div class="flex flex-row justify-start items-center">

    <div class="flex flex-row mr-2">
      <div @click="toggleShow(farbe)" v-for="farbe in farben"
           @mouseover="showDescription(farbe)"
           @mouseout="hideDescription(farbe)"
           :class="{'farbe--inactive': !farbe.show }"
           class="farbe-explanation--group flex  flex-row  justify-start items-center cursor-pointer p-1 text-base"
           :style="farbe.style">
        <i v-if="farbe.show" class="fas fa-check-square inline-block align-self-center" :style="farbe.style"></i>
        <i v-else class="fas fa-square inline-block align-self-center" :style="farbe.style"></i>
      </div>
    </div>
    <div v-html="description" class="text-sm text-primary-300"></div>
  </div>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

export default {
  name: "farben-min",
  props: ['farben'],
  data: function () {
    return {

      description: '',
    }
  },

  methods: {
    showDescription(farbe) {
      this.description = farbe.description;
    },
    hideDescription(farbe) {

      this.description = '';
    },
    toggleShow (farbe) {
      EventBus.$emit('toggleShowFarbe', farbe)
    }
  },
  mounted () {

  }
}
</script>

<style scoped>
div.farbe--inactive {
  color: #bbb !important;
}
</style>