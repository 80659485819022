<template>
  <div class="flex flex-row items-center">
    <b-tooltip label="Die Musterlösung ist nicht verfügbar oder wurde nicht in der Textkonfiguration aktiviert." :active="!this.$parent.$parent.$parent.AnswersAreEnabled"
               position="is-right">
      <button @click="this.toggleShow" :disabled="!this.$parent.$parent.$parent.AnswersAreEnabled" dusk="reader__header__toolbar__answers" class="flex flex-row items-center disabled:opacity-50">
        <hermeneus-icon name="hi-answers-min" height="20" width="20" class="hi-color-primary-300-hover mr-2"></hermeneus-icon>
      </button>
    </b-tooltip>
    <div v-if="this.showAnswers" class="reader__answers__all flex flex-col">
      <div class="reader__header--close flex-group-sb not-responsive text-2xl bg-very-light-grey">
        <div class="p-3 flex flex-row justify-start items-center">
          <hermeneus-icon width="28" height="28" name="hi-answers-min" color="hi-color-green-light" class="mr-2"></hermeneus-icon>
          <div>Musterlösung</div>
        </div>
        <button @click="close" class="reader__header--close-button p-3"><i class="hi hi-close"></i></button>
      </div>
      <div class="reader__musterloesung-all__body flex flex-col p-12 text-black text-lg">
        <div class="flex flex-row justify-end p-2">
          <button class="p-2 text-xl" :class="{'text-grey-800': this.viewMode ==='list', 'text-grey-300': this.viewMode=== 'text'}" @click="toggleView('list')"><i class="fas fa-list-ol"></i></button>
          <button class="p-2 text-xl" :class="{'text-grey-800': this.viewMode ==='text', 'text-grey-300': this.viewMode=== 'list'}" @click="toggleView('text')"><i class="fas fa-align-justify"></i>
          </button>
        </div>
        <div v-if="viewMode === 'list'">
          <div v-for="answer in this.$parent.$parent.$parent.Answers" class="flex flex-row justify-start mt-2">
            <div class="flex flex-row justify-end">
              <div v-text="answer.sentence_n" class="mr-4 font-bold"></div>
            </div>
            <div class="flex flex-col">
              <div class="font-serif font-bold" v-text="answer.sentence_string"></div>
              <div v-text="answer.answertext"></div>
            </div>
          </div>
        </div>
        <div v-else>
          <span v-for="answer in this.$parent.$parent.$parent.Answers" class="flex flex-row justify-start" v-text="answer.answertext">

          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "reader-header-toolbar-answers",
  data: function () {
    return {
      showAnswers: false,
      Sentences: [],
      viewMode: "list"
    }
  },
  methods: {
    toggleShow () {
      if (this.$parent.$parent.$parent.AnswersAreEnabled) {
        this.showAnswers = !this.showAnswers;
      } else {
        this.showAnswers = false;
      }
    },
    toggleView (mode) {
      this.viewMode = mode
    },
    close () {
      this.showAnswers = false;
    }
  }
}
</script>

<style scoped>
.reader__answers__all {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-content: space-between; */
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  line-height: 2rem;
  z-index: 900;
  overflow: scroll;
}
</style>