<template>
    <div>
        <div @click="toggleShow(farbe)" v-for="farbe in farben"
             :class="{'farbe--inactive': !farbe.show }"
             class="farbe-explanation--group flex flex-row justify-start items-center cursor-pointer p-1 text-base md-plus:p-3 md-plus:text-lg rounded-sm"
             :style="farbe.style">
            <i v-if="farbe.show" class="hi hi-checkbox-checked inline-block align-self-center"></i>
            <i v-else class="hi hi-checkbox-unchecked inline-block align-self-center"></i>
            <div v-html="farbe.description" class="ml2"></div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

    export default {
        name: "farben",
        props: ['farben'],
        methods: {
            toggleShow (farbe) {
                EventBus.$emit('toggleShowFarbe', farbe)
            }
        },
    }
</script>

<style scoped>
    div.farbe--inactive {
        color: #bbb !important;
    }
</style>