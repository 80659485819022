<template>

</template>

<script>
import {EventBus} from "../../../hermeneus.eventbus/eventbus";
import Reader from "../reader.class_reader";

export default {
  name: "HasToolSubjPred",
  data: function () {
    return {
      active: false,
      SubjPred: [],


    }
  },
  computed: {
    hasSubjPred: function () {
      return Object.keys(this.SubjPred).length > 0;

    }
  },
  methods: {
    async toggle () {
      this.active = !this.active;
      this.active ? EventBus.$emit('toggleOnWorkspaceSubjPred') : EventBus.$emit('toggleOffWorkspaceSubjPred');
    }
  },
  mounted () {
    this.SubjPred = Reader.getSubjPredElements(this.$parent.$parent.$parent.$refs['workspace'].$el);

  }
}
</script>

<style scoped>

</style>