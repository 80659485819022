<template>
  <div id="reader__header-toolbar" class="p-1 w-full flex flex-row justify-start">
    <reader-header-toolbar-answers v-if="$parent.$parent.hasAnswers"></reader-header-toolbar-answers>
    <reader-header-toolbar-subjpred></reader-header-toolbar-subjpred>
    <reader-header-toolbar-farben :farben="$parent.$parent.Farben" class="flex-1"></reader-header-toolbar-farben>
  </div>
</template>

<script>
import ReaderHeaderToolbarFarben from "./ReaderHeaderToolbarFarben.vue"
import ReaderHeaderToolbarSubjPred from "./ReaderHeaderToolbarSubjPred.vue"
import ReaderHeaderToolbarAnswers from "./ReaderHeaderToolbarAnswers.vue"

export default {
name: "reader-header-toolbar",
  components: {
    ReaderHeaderToolbarFarben,
    ReaderHeaderToolbarAnswers,
    'reader-header-toolbar-subjpred': ReaderHeaderToolbarSubjPred,
  }
}
</script>

<style scoped>

</style>