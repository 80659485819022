<template>
  <div class="reader__header flex-col">
    <div v-show="!printmode" class="hermeneus-textinfo__top-bar flex-group-sb flex-items-sb white p-1 pl-2 flex-ratio-1 not-responsive">
      <a class="hermeneus-textinfo__top-bar__title flex-items-center flex-group-start mr-8 text-white hover:text-secondary_color" href="https://www.hermeneus.eu" target="_blank">
        <i class="hi hi-sentence mr2"></i>
        <div class="italic text-lighter">hermeneus.reader</div>
      </a>
<!--      <reader-header-toolbar></reader-header-toolbar>-->
      <div class="reader-header__buttons flex flex-row justify-end">
        <gravitas-counter v-if="$userData && $parent.data.config.analysis_gravitas_cost" class=" mr-8">

        </gravitas-counter>

        <button
            :class="{'text-primary-600': $parent.WordAnalysisShowMorpho, 'text-primary-300': !$parent.WordAnalysisShowMorpho, 'hover:text-white': $parent.data.config.analysis_show_morpho}"
            id="demo-tour__showMorpho"
            title="Morphologische Analyse anzeigen"
            :disabled="!$parent.data.config.analysis_show_morpho"
            class="show-morpho-btn cursor-pointer  text-lg mr-3" @click="toggleShowMorpho()">
          <i class="hi hi-morph-table align-middle"></i>
        </button>
        <hermeneus-feedback
            :subject="$parent.data.textdata.title"
            :commentable_id="$parent.data.textdata.id"
            :commentable_type="'App\\Models\\HermeneusText'"
            :disabled_if="!$userData"
            :button_size="18"
            :icon_color="'hi-color-primary-300-hover'"
            :button_style="'p1 white no-outline hover-secondary mr-1'"></hermeneus-feedback>
        <!--<slot class=" flex-ratio-1"></slot>-->
        <button @click="showHelp()" class="text-primary-300 hover:text-white"><i class="fas fa-question text-lg mx-2"></i></button>
        <button @click="activatePrintMode()" class="text-primary-300 hover:text-white"><i class="fas fa-file text-lg mx-2"></i></button>
      </div>

    </div>
    <div v-show="printmode" class="reader__printmode  bg-primary-300 p-2  flex flex-row justify-between items-center no-print">
      <div class="flex flex-row justify-start items-center">
        <div class="mr-4 font-bold">Druckansicht:</div>
        <b-checkbox v-model="$parent.PrintDoubleSpacing" severity="primary" class="">
          Doppelter Zeilenabstand
        </b-checkbox>
      </div>
      <div>
        <button @click="print()" class=" mr-6 hover:text-primary-500">

          <i class="fas fa-print text-lg"></i>
        </button>
        <button @click="deactivatePrintMode" class=" mr-6 hover:text-primary-500">

          <i class="far fa-times-circle text-xl"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";
import ReaderHeaderToolbar from "./ReaderHeaderToolbar.vue"

export default {
  name: "reader-header",
  data: function () {
    return {

      printmode: false,
      doublespacing: false,

    }
  },
  components: {
    ReaderHeaderToolbar,
  },

  methods: {
    print () {
      window.print();
    },
    activatePrintMode () {
      this.printmode = true;
      /*   window.print();*/
    },
    deactivatePrintMode () {
      this.printmode = false;
      /*   window.print();*/
    },
    toggleShowMorpho () {
      EventBus.$emit('toggleWordAnalysisShowMorpho')
    },
    showHelp () {
      EventBus.$emit('openReaderIntroduction');
    }
  }
}
</script>

<style scoped>

</style>